ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.selectWrapper {
  position: relative;
}
.selectWrapper .inputTrashWrapper {
  display: flex;
  align-items: center;
}
.selectWrapper .inputWrapper {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding: 0 10px;
  padding-right: 34px;
  border-radius: 20px;
  border: 1px solid;
  border-color: #F0F0F0;
  background-color: #F0F0F0;
}
.selectWrapper .inputWrapper .myInput {
  background-color: #F0F0F0;
  cursor: pointer;
  width: 2px;
  border: 0px;
  outline: 0px;
  padding: 0px;
  color: inherit;
  margin: 2px;
  font-size: inherit;
}
.selectWrapper .inputWrapper .fakeInput {
  position: absolute;
  top: 0px;
  left: 0px;
  visibility: hidden;
  font-size: inherit;
  height: 0;
}
.selectWrapper .inputWrapper .selectValue, .selectWrapper .inputWrapper .placeholder {
  color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
} 
.selectValue {
  left: 9px;
  right: 25px;
  justify-content: flex-start !important;
}
.selectValueText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.selectWrapper .inputWrapper .placeholder {
  color: #303030;
}

.selectWrapper .inputWrapper .clearAllIcon, .selectWrapper .inputWrapper .dropdownIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.selectWrapper .inputWrapper .clearAllIcon {
  right: 45px;
  fill: #c0c4cc;
}
.selectWrapper .inputWrapper .clearAllIcon:hover {
  fill: #999;
}
.selectWrapper .inputWrapper .dropdownIcon {
  fill: #303030;
  right: 10px;
  transition: all 0.3s;
}
.selectWrapper .inputWrapper .dropdownIcon.active {
  transform: translateY(-50%) rotate(180deg);
}
.selectListWrapper {
 max-height: 200px;
}
.selectWrapper .selectListWrapper {
  overflow: auto;
  position: absolute;
  top: 80px;
  border: 1px solid #F0F0F0;
  border-radius: 0 0 5px 5px;
  background-color: #F0F0F0;
  box-shadow: 0 0 6px 0 #A9A9A9;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0;
  z-index: 1001;
}
.selectWrapper .selectListWrapper .selectList {
  padding: 6px 0;
}
.selectWrapper .selectListWrapper .selectList .selectListItem, .selectWrapper .selectListWrapper .selectList .noItem, .selectWrapper .selectListWrapper .selectList .loadingItem {
  font-size: 14px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
}
.selectListWrapper .selectList .noItem, .selectWrapper .selectListWrapper .selectList .loadingItem {
  padding: 0 20px;
}
.selectWrapper .selectListWrapper .selectList .selectListItem {
  color: #303030;
  cursor: pointer;
  font-size: 14px;
}
.selectListItem {
  display: flex;
  align-items: center;
}
.checkbox {
  margin: 0 0 0 20px;
}
.selectWrapper .selectListWrapper .selectList .noItem, .selectWrapper .selectListWrapper .selectList .loadingItem {
  color: #303030;
  text-align: center;
  cursor: default;
}
.selectWrapper .selectListWrapper .selectList .loadingItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectWrapper .selectListWrapper .selectList .loadingItem .loadingIcon {
  padding-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectWrapper .selectListWrapper .selectList .loadingItem .loadingIcon svg {
  fill: #808080;
  animation: loading 1.5s infinite linear;
}
.selectWrapper .selectListWrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
.selectWrapper .selectListWrapper::-webkit-scrollbar-track {
  background-color: transparent;
}
.selectWrapper .selectListWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(144, 147, 153, 0.3);
}
.selectWrapper .selectListWrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.5);
}
.multiSelectItem {
  background-color: #EDC899;
  border-radius: 15px;
  color: #60441F;
  padding: 5px 12px;
  font-size: 12px;
  margin: 0 0 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multiSelectItemRemove {
  cursor: pointer;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(360deg);
 }
}
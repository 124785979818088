.react-date-picker__wrapper {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  height: 40px;
  padding: 0 15px;
}

.react-date-picker__error .react-date-picker__wrapper {
  border: 1px solid #BF2933;
}

.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-date-picker__inputGroup__divider {
  color: #303030;
}